<template>
  <div class="">
        <MainUserComponent :parent="parent">
        </MainUserComponent>
  </div>
</template>

<script>
import store from '@/state/store.js'
import MainUserComponent from './mainsUsersComponent'
import userSegments from './userSegments.vue'
import appConfig from '@/app.config'
export default {
    page: {
		title: 'Coporate Users',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    name:'corporate-users',
    components: {
        MainUserComponent,
        userSegments
    },
    data() {
        return {
            parent:{
                name:'corporate',
                storeDataName: 'corporateUsers',
                dispatchName: 'admin/users/getCorporateUsers',
                searchDispatchName: 'admin/users/searchCorporateUsers',
            },
        }
    },
    methods:{
    },
    computed: {
    },
    mounted() {
    //    store.dispatch('admin/users/getCorporateUsers')
    }
}
</script>

<style lang="scss" scoped>
#enter__account {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #F7921C;
    outline:none;
    border:none;
    background-color: transparent;
}
#__edit{
    outline:none;
    border:none;
    background:transparent;
    margin-left: .5em;
    width: 10px!important;
    padding:0px;
}
</style>